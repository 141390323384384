<script lang="ts">
import { type SlotsType, vShow, Transition, type VNode, type Ref } from 'vue'
import type { ScssBreakpoint } from '@core-types/scss'
import type { CollapseData } from '@core/app/composables/collapse'

export type BaseUtilCollapseProps = {
    modelValue?: boolean | CollapseData
    expandedBreakpoint?: ScssBreakpoint
    collapsedSize?: number
    direction?: 'horizontal' | 'vertical'
    duration?: number
    containerId?: string
}

type BaseUtilCollapseSlots = {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUtilCollapse(options?: ComponentOverrideOptions<ComponentOptions, BaseUtilCollapseProps, BaseUtilCollapseSlots>) {
    return defineComponent(
        (props: BaseUtilCollapseProps, ctx) => {

            const el = ref<HTMLDivElement | null>(null)
            const customEl: Ref<HTMLDivElement | null> = ref(null)

            const sizeProperty = props.direction === 'horizontal' ? 'width' : 'height' as const
            const oppositeSizeProperty = props.direction === 'horizontal' ? 'height' : 'width' as const

            const modelValue = computed({
                get() {
                    return props.modelValue
                },
                set(val: CollapseData | boolean) {
                    ctx.emit('update:modelValue', val)
                },
            })

            const expandedBreakpoint = props.expandedBreakpoint ?? (typeof modelValue.value !== 'boolean' && modelValue.value ? modelValue.value.expandedBreakpoint : null)

            const showContent = computed<boolean>({
                get() {
                    if (typeof modelValue.value === 'boolean') {
                        return modelValue.value ?? false
                    }

                    return modelValue.value?.isExpanded ?? false
                },
                set(val: boolean) {
                    if (typeof modelValue.value === 'boolean') {
                        modelValue.value = val
                    } else if (modelValue.value) {
                        modelValue.value = {
                            ...modelValue.value,
                            isExpanded: val,
                        }
                    }
                },
            })

            const containerId = typeof modelValue.value !== 'boolean' && modelValue.value ? modelValue.value.id : props.containerId

            watch(showContent, (val) => {
                isContentAriaHidden.value = !val && !isOverExpandedBreakpoint?.value
            })

            if (props.collapsedSize) {
                watch(showContent, async (val) => {
                    if (!customEl.value) return

                    if (!val) {
                        onLeave(customEl.value)
                    } else {
                        onEnter(customEl.value)
                        await new Promise(resolve => setTimeout(resolve, 200))
                        afterEnter(customEl.value)
                    }
                }, { immediate: true })
            }

            const isOverExpandedBreakpoint = expandedBreakpoint ? useScssBreakpoints().greaterOrEqual(expandedBreakpoint) : null
            // if the ref is present, register a watcher
            if (isOverExpandedBreakpoint) {
                watch(isOverExpandedBreakpoint, (val) => {
                    isContentAriaHidden.value = !val && !showContent.value

                    if (val && !showContent.value) {
                        if (!el.value) return
                        el.value.style[sizeProperty] = 'auto'
                    }
                })
            }

            /*
                TRANSITION CALLBACKS
             */

            function onEnter(el: Element) {
                const element = el as HTMLDivElement

                element.style[oppositeSizeProperty] = getComputedStyle(element)[oppositeSizeProperty]
                element.style.position = 'absolute'
                element.style.visibility = 'hidden'
                element.style[sizeProperty] = 'auto'

                const size = getComputedStyle(element)[sizeProperty]

                element.style[oppositeSizeProperty] = null!
                element.style.position = null!
                element.style.visibility = null!
                element.style[sizeProperty] = props.collapsedSize ? `${props.collapsedSize}px` : '0'

                // Force repaint to make sure the
                // animation is triggered correctly.

                getComputedStyle(element)[sizeProperty]

                // Trigger the animation.
                // We use `requestAnimationFrame` because we need
                // to make sure the browser has finished
                // painting after setting the `height`
                // to `0` in the line above.
                requestAnimationFrame(() => {
                    element.style[sizeProperty] = size
                })
            }

            function afterEnter(el: Element) {
                const element = el as HTMLDivElement
                element.style[sizeProperty] = 'auto'
                ctx.emit('afterEnter')
            }

            function onLeave(el: Element) {
                const element = el as HTMLDivElement
                element.style[sizeProperty] = getComputedStyle(element)[sizeProperty]

                // Force repaint to make sure the
                // animation is triggered correctly.


                getComputedStyle(element)[sizeProperty]

                requestAnimationFrame(() => {
                    element.style[sizeProperty] = props.collapsedSize ? `${props.collapsedSize}px` : '0'
                })
            }

            const isContentAriaHidden = ref<boolean>(!expandedBreakpoint && !showContent.value)
            onMounted(() => {
                if (isOverExpandedBreakpoint === null) return
                isContentAriaHidden.value = !isOverExpandedBreakpoint?.value
            })


            return () => props.collapsedSize
                ? h('div', {
                    id: containerId,
                    ref: customEl,
                    class: ['sim-collapse', 'v-enter-active', `sim-collapse--${props.direction}`, {
                        [`expanded--${expandedBreakpoint}`]: expandedBreakpoint,
                    }],
                    style: {
                        [sizeProperty]: `${props.collapsedSize}px`,
                        '--collapse-enter-d': props.duration ? `${props.duration}ms` : undefined,
                        '--collapse-leave-d': props.duration ? `${props.duration}ms` : undefined,
                    },
                }, [
                    renderSlot(ctx.slots.default, options?.slots?.default, {}) as VNode[],
                ]
                )
                : h(Transition, {
                    onEnter: onEnter,
                    onAfterEnter: afterEnter,
                    onLeave: onLeave,
                }, () => withDirectives(
                    h('div', {
                        'id': containerId,
                        'ref': el,
                        'class': ['sim-collapse', `sim-collapse--${props.direction}`, {
                            [`sim-collapse--${expandedBreakpoint}`]: expandedBreakpoint,
                        }],
                        'style': {
                            '--collapse-enter-d': props.duration ? `${props.duration}ms` : undefined,
                            '--collapse-leave-d': props.duration ? `${props.duration}ms` : undefined,
                        },
                        'inert': isContentAriaHidden.value,
                        'aria-hidden': isContentAriaHidden.value,
                    }, [
                        renderSlot(ctx.slots.default, options?.slots?.default, {}) as VNode[],
                    ]
                    ),
                    [
                        [vShow, showContent.value],
                    ]))
        },
        {
            props: defineRuntimeProps<BaseUtilCollapseProps>({
                modelValue: { type: [Boolean, Object ] },
                // @ts-ignore
                expandedBreakpoint: { type: String },
                collapsedSize: { type: Number },
                // @ts-ignore
                direction: { type: String, default: 'vertical' },
                duration: { type: Number },
                containerId: { type: String },
            }, options),
            slots: Object as SlotsType<BaseUtilCollapseSlots>,
            emits: {
                'update:modelValue': (val: boolean | CollapseData) => true,
                'afterEnter': () => true,
            },
        }
    )
}

export default defineComponentBaseUtilCollapse()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUtilCollapse.scss" as *;
@include set-duration(300ms);
@include set-timing(cubic-bezier(.4,0,.2,1));

</style>
